import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserProfileService } from './userProfile.service';

import { SharedPopupComponent } from '../components/shared-popup/shared-popup.component';


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

    constructor(
        private userProfileService: UserProfileService,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
        const token = this.userProfileService.getAuthToken();
        const lang = 'es';
        let newHeaders = req.headers;
        let newParams = req.params;
        if (token) {
            newHeaders = newHeaders.set('Authorization', token);
        }
        if (lang) {
            newParams = newParams.set('locale', lang);
        }
        if (!(req.body && req.body instanceof FormData)) {
            newHeaders = newHeaders.set('Content-Type', 'application/json');
        }
        // Finally we have to clone our request with our new headers
        // This is required because HttpRequests are immutable
        const request = req.clone({ headers: newHeaders, params: newParams });
        // Then we return an Observable that will run the request
        // or pass it to the next interceptor if any
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 0) {
                    this.dialog.open(SharedPopupComponent, {
                        width: '400px',
                        disableClose: true,
                        data: {
                            title: '¡Ups!',
                            subtitle: 'Parece que hay un problema de conexión.',
                            content: {
                                type: 'text',
                                text: ["Ayudanos verificando que tengas internet, " +
                                "si el problema persiste, porfavor contactanos por medio del chat de ayuda."]
                            },
                            buttons: [
                                {
                                    text: 'Ok',
                                    action: () => {
                                        this.router.navigate(['/']);
                                        this.dialog.closeAll();
                                    }
                                }
                            ]
                        }
                    });
                } else if (error.status === 401) {
                    this.dialog.open(SharedPopupComponent, {
                        width: '400px',
                        disableClose: true,
                        data: {
                            title: '¡Alto ahí!',
                            subtitle: 'No cuentas con el permiso para acceder a esta sección.',
                            content: {
                                type: 'text',
                                text: ["Si crees que esto es un error, porfavor contactanos por medio del chat de ayuda."]
                            },
                            buttons: [
                                {
                                    text: 'Ok',
                                    action: () => {
                                        this.router.navigate(['../'], { relativeTo: this.route });
                                        this.dialog.closeAll();
                                    }
                                }
                            ]
                        }
                    });
                } else if (error.status === 500) {
                    this.dialog.open(SharedPopupComponent, {
                        width: '400px',
                        disableClose: true,
                        data: {
                            title: '¡Ups!',
                            subtitle: 'Parece que salió un error, porfavor vuelve a intentar.',
                            content: {
                                type: 'text',
                                text: ["Si el problema persiste ayudanos reportando este problema, porfavor contactanos por medio del chat de ayuda."]
                            },
                            buttons: [
                                {
                                    text: 'Ok',
                                    action: () => {
                                        this.dialog.closeAll();
                                    }
                                }
                            ]
                        }
                    });
                }
                return throwError(error);
            })
        ) as any;
    }
}
